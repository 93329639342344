import { Router, useLocation } from '@reach/router'

import Posts from './components/posts'

function NotFound() {
  return (
    <h1>
      Page not found at "<b>{useLocation().pathname}</b>"
    </h1>
  )
}

function App() {
  return (
    <Router>
      <Posts path="/" />
      <NotFound path="*" />
    </Router>
  )
}

export default App
