import React, { useState, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function AddPost({ open, update, refresh }) {
  const cancelButtonRef = useRef(null)
  const contentRef = useRef(null)
  const titleRef = useRef(null)
  const usernameRef = useRef(null)
  const [img, setImg] = useState(null)
  const [error, setError] = useState(null)

  const onChange = (e) => {
    let file = e.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = (re) => {
        let img = btoa(re.target.result)
        setImg(img)
        return img
      }

      reader.readAsBinaryString(file)
    }
  }

  const reset = () => {
    setImg(null)
    setError(null)
  }

  const createPost = () => {
    if (titleRef.current.value && contentRef.current.value && usernameRef.current.value) {
      const data = {
        username: usernameRef.current.value,
        title: titleRef.current.value,
        content: {
          text: contentRef.current.value,
          img,
        },
      }

      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
      fetch(`${process.env.REACT_APP_BACKEND_HOST}/posts`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            refresh()
            reset()
            update(false)
          } else {
            setError(data.errors[0])
          }
        })
        .catch((err) => {
          setError('Server error or no internet connection.')
        })
    } else {
      setError('Please enter username, title and content')
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={(show) => {
          update(show)
          reset()
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Create Post
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        It may take around 60 seconds for a post to appear.
                      </p>

                      {error && <p className="text-red-500">{error}</p>}

                      <form>
                        <input
                          type="text"
                          className="mt-1 block w-full border-solid border-2 border-light-blue-500 focus:outline-none focus:ring-2 rounded focus:ring-indigo-500 focus:placeholder-indigo-300 p-2"
                          placeholder="Username"
                          ref={usernameRef}
                        />
                        <input
                          type="text"
                          className="mt-1 block w-full border-solid border-2 border-light-blue-500 focus:outline-none focus:ring-2 rounded focus:ring-indigo-500 focus:placeholder-indigo-300 p-2"
                          placeholder="Title"
                          ref={titleRef}
                        />

                        <textarea
                          type="text"
                          className="mt-1 block w-full border-solid border-2 border-light-blue-500 focus:outline-none focus:ring-2 rounded focus:ring-indigo-500 focus:placeholder-indigo-300 p-2"
                          placeholder="Post Content"
                          rows="4"
                          ref={contentRef}
                        ></textarea>

                        <div className="m-4">
                          <div className="flex items-center justify-center w-full">
                            <label className="flex flex-col w-36 h-36 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                              {img && (
                                <img
                                  className="object-contain h-32 w-full"
                                  src={`data:image/png;base64,${img}`}
                                  alt="Post Preview"
                                />
                              )}
                              {!img && (
                                <div
                                  className="flex flex-col items-center justify-center pt-7"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                    Select a photo
                                  </p>
                                </div>
                              )}

                              <input
                                type="file"
                                name="image"
                                id="file"
                                accept=".jpg, .png, .jpeg"
                                className="opacity-0"
                                onChange={(e) => onChange(e)}
                              />
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-600 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={createPost}
                >
                  Post
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    update(false)
                    reset()
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
