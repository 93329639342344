import React, { useEffect, useState } from 'react'
import PostCard from './postCard'
import AddPost from './addPost'

const Posts = () => {
  const [posts, setPosts] = useState([])
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(false)

  const getPosts = () => {
    fetch(`${process.env.REACT_APP_BACKEND_HOST}/posts`)
      .then((resp) => resp.json())
      .then(setPosts)
      .catch((err) => {
        setError('Server error or no internet connection.')
      })
  }

  useEffect(() => {
    getPosts()
  }, [])

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>

  return (
    <div>
      <div className="relative bg-gray-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 border-b-2 border-gray-100 py-3">
          <div className="flex justify-between items-center">
            <h1 class="text-2xl font-bold leading-7 text-white sm:truncate">
              Dummy Social Media Site
            </h1>
            <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
              <button
                onClick={() => setOpen(true)}
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-gray-100 hover:bg-gray-200"
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <AddPost
        open={open}
        update={setOpen}
        refresh={() => {
          setTimeout(getPosts, 20000)
        }}
      />
      <div className="w-full mx-auto md:w-2/5 flex p-3"></div>
      <PostCard posts={posts} />
    </div>
  )
}

export default Posts
