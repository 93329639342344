import moment from 'moment'
const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const renderText = (id, txt) =>
  txt.split(' ').map((part) =>
    URL_REGEX.test(part) ? (
      <a
        className="text-purple-600"
        href={part}
        target="_blank"
        rel="noreferrer noopener"
        key={id}
      >
        {part}{' '}
      </a>
    ) : (
      part + ' '
    ),
  )

export default function PostCard({ posts }) {
  return posts.map((post) => {
    return (
      <div key={post.id}>
        <section className="w-full mx-auto md:w-2/5 flex flex-col items-center px-3">
          <article className="flex flex-col shadow my-2 w-full">
            <div className="bg-white flex flex-col justify-start overflow-hidden">
              {/* Header */}
              <div className="p-4">
                <h2
                  href="#"
                  className="text-3xl font-bold hover:text-gray-700 pb-2"
                >
                  {post.title}
                </h2>
                <p className="text-sm">
                  <span className="font-semibold hover:text-gray-800">
                    @{post.username}
                  </span>{' '}
                  &bull; {moment(post.published_at).format('lll')}
                </p>
              </div>
              {/* Text Content */}
              <div className="p-4 pt-0">
                <p>{renderText(post.id, post.content.text)}</p>
              </div>
              <hr />
              {post.content.img && (
                <img
                  className="object-fill h-96 w-full"
                  src={`data:image/png;base64,${post.content.img}`}
                  alt={post.title}
                />
              )}
            </div>
          </article>
        </section>
      </div>
    )
  })
}
